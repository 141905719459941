<template>
  <div class="page">
    <div
      class="page-header-with-title"
      :style="{ 'background-image': 'url(' + headerImage + ')' }"
    >
      <h1>
        Leaderboard
      </h1>
    </div>
    <Content class="leaderboard__content">
      <Leaderboard />
    </Content>
  </div>
</template>

<script>
import Leaderboard from '@/components/global/Leaderboard'

export default {
  components: {
    Leaderboard
  },
  data() {
    return {
      headerImage: require('@/assets/images/headers/leaderboard.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/page-header';

.leaderboard__content {
  @include respond-above(sm) {
    width: 80%;
    margin: 60px auto auto;
  }
}
</style>
